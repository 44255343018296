import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Result from './views/result'
import Await from './views/await'
import Homepage from './views/homepage'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'result',
      path: '/result',
      component: Result,
    },
    // {
    //   name: 'await',
    //   path: '/await',
    //   component: Await,
    // },
    {
      name: 'homepage',
      path: '/',
      component: Homepage,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
