<template>
  <div class="result-container">
    <div class="await-container" id="await-container">
    <div class="await-await">
      <div class="await-content">
        <div class="await-banner">
          <img
            alt="logo2679"
            src="/external/loading.png"
            class="await-logo"
          />
          <span class="await-text"><span>雅思写作帮手</span></span>
          <span class="await-text2"><span>{{ this.awaitText }}</span></span>
        </div>
      </div>
    </div>
  </div>
    <div class="result-result">
      <div class="result-content">
        <div class="result-banner">
          <img
            alt="logo2979"
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/90e78341-6eff-4f32-bdbe-b13a6006bea5/ef784f6d-5299-4485-b62c-03426cd2912d?org_if_sml=13597&amp;force_format=original"
            class="result-logo"
          />
          <span class="result-text"><span>雅思写作帮手</span></span>
        </div>
        <div class="result-frame22">
          <div class="result-score">
            <div class="result-frame">
              <div class="result-scoretotal">
                <span class="result-text02"><span>预估总分</span></span>
                <span class="result-text04"><span>{{ this.overall }}</span></span>
              </div>
              <div class="result-container1">
                <div class="result-scoreachievement">
                  <span class="result-text06"><span>完成任务</span></span>
                  <span class="result-text08"><span>{{ this.ta }}</span></span>
                </div>
                <div class="result-scorecohesion">
                  <span class="result-text10"><span>连贯衔接</span></span>
                  <span class="result-text12"><span>{{ this.cc }}</span></span>
                </div>
                <div class="result-scorelexical">
                  <span class="result-text14"><span>词汇表现</span></span>
                  <span class="result-text16"><span>{{ this.lr }}</span></span>
                </div>
                <div class="result-scoregrammar">
                  <span class="result-text18"><span>语法结构</span></span>
                  <span class="result-text20"><span>{{ this.gra }}</span></span>
                </div>
              </div>
            </div>
            <span class="result-text22"><span>*评分结果仅供参考，即便是同一篇文章，评分也会稍有差别</span></span>
          </div>
          <div class="result-advice">
            <span class="result-text24"><span>任务题目</span></span>
            <span class="result-text26">
              <span v-html="this.task">
                
              </span>
            </span>
          </div>
          <div class="result-advice">
            <span class="result-text24"><span>修改意见</span></span>
            <span class="result-text26">
              <span v-html="this.advice">
                
              </span>
            </span>
          </div>
          <div class="result-reference" id="revised-response">
            <div class="result-text28"><span>参考范文</span><button type="button" @click="toggleOrginalResponse" class="button button-stylized-minimized">查看原文</button></div>
            <span class="result-text30">
              <span>
                <span  v-html="this.revised ">

                </span>
                <br />
                <span></span>
              </span>
            </span>
          </div>
          <div class="result-original hidden" id="original-response">
            <div class="result-text28"><span>原文</span><button type="button" @click="toggleOrginalResponse" class="button button-stylized-minimized">查看参考范文</button></div>
            <span class="result-text30">
              <span>
                <span>
                  {{ this.writingResponse  }}
                </span>
                <br />
                <span></span>
              </span>
            </span>
          </div>
          <div class="result-bottombar">
            <button type="button" @click="sendRequest" class="button button-stylized" v-if="this.isTrial != 1">评分不对，再来一次</button>
            <button type="button" @click="()=>{this.$router.replace({ path: '/'});}" class="button button-stylized">再改一篇</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Result',
  metaInfo: {
    title: '作文批改',
  },
  data(){
    return{
      awaitText:"正在批改中，请稍等",
      writingResponse:"",
      overall:0,
      ta:0,
      cc:0,
      lr:0,
      gra:0,
      revised:"",
      advice:"",
      isShowingOriginal: false,
      isTrial:0,
      task:""
    }
  },
  mounted() {
    // console.log(this.$route.query.data);
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Add smooth scrolling animation
      });
    this.writingResponse = this.$route.query.data;
    if(this.$route.query.isTrial==1){
      this.isTrial = 1;
    }
    if(this.isTrial == 1){
      this.overall = 7.5;
      this.ta = 7.5;
      this.cc = 8;
      this.lr = 7;
      this.gra= 7.5;
      this.task = "Discuss the reasons behind the trend of promoting products based on their novelty and whether this is a favorable development."
      this.revised = "In today's commercial landscape, there is a notable trend in promoting products based on their novelty. This essay will explore the reasons behind this phenomenon and argue that, overall, it is a favorable development. <br /> <br />One reason for highlighting the innovative aspects of products is that humans naturally gravitate towards new things. Consumer psychology research has shown that customers are inherently drawn to novelty, making them more likely to buy items marketed as cutting-edge. For example, smartphone manufacturers often highlight the latest features in their devices, such as advanced cameras, foldable screens, and faster processors, to capitalize on this innate human curiosity. <br /> <br />Another reason is related to perceived value. When a product is presented as innovative, it creates the perception that it is more valuable than its competitors. This can be a significant motivator for consumers seeking the best possible experience and wanting to feel they are getting a good return on their investment. Consequently, they may be more inclined to choose a product with state-of-the-art functionalities, even if it comes at a higher price.<br /> <br />Despite some potential drawbacks, such as promoting a culture of constant consumption and planned obsolescence, the overall impact of this advertising strategy is primarily beneficial. The focus on novelty stimulates technological breakthroughs, which can enhance the quality of life for many individuals. Furthermore, it encourages businesses to invest in research and development, fostering economic growth and creating employment opportunities.<br /> <br />In conclusion, the emphasis on novelty in advertising is driven by human neophilia and the perceived value of newer products. While certain negative consequences may arise, the positive implications, including the promotion of innovation and economic growth, position this development as predominantly advantageous.";
      this.advice = "Ensure that each paragraph focuses on a specific point or argument. Provide more specific examples to support your reasoning. Use transition words and phrases to improve the flow of your essay.";
      setTimeout(() => {
        document.getElementById('await-container').classList.add('hidden');

      }, 2500);
      return;
    }
    //if it's in localhost, use the local api
    if(window.location.hostname != "localhost"){
      this.sendRequest();
    }else{
      this.overall = 7.5;
      this.ta = 7.5;
      this.cc = 7.5;
      this.lr = 7.5;
      this.gra= 7.5;
      this.revised = "This is a revised text";
      this.advice = "This is a advice";
      document.getElementById('await-container').classList.add('hidden');
    }
    
  },methods: {
    toggleOrginalResponse(){
      if(this.isShowingOriginal){
        document.getElementById('original-response').classList.add('hidden');
        document.getElementById('revised-response').classList.remove('hidden');
        this.isShowingOriginal = false;
      }else{
        document.getElementById('original-response').classList.remove('hidden');
        document.getElementById('revised-response').classList.add('hidden');
        this.isShowingOriginal = true;
      }
    },
    throwError(error,text){
      document.getElementById('await-container').classList.remove('hidden');
          console.error('Error: ', error);
          this.awaitText = text;
    }
    ,
    async sendRequest() {
      document.getElementById('await-container').classList.remove('hidden');

      var chatResponse;
      console.log(this.writingResponse)
      // Assuming you have an API endpoint to send the POST request
      await axios.post('https://ielts-writing-helper.capsuleer.me/api_ielts', {"answerResponse":this.writingResponse})
        .then(response => {
          try{
          if(response.hasOwnProperty("data")){
            chatResponse = JSON.parse(response.data);
          }
          // Assuming the response contains a property called 'jsonData'
          console.log(response)
          }catch(e){
            this.throwError(e,"发生错误，这不是你的问题。请稍后重试。")
          }
          
        })
        .catch(error => {
          this.throwError(error,"发生错误，这不是你的问题。请稍后重试。")
          return;
          
        });

      if(chatResponse.hasOwnProperty("revised") && chatResponse.hasOwnProperty("overall")
      &&chatResponse.hasOwnProperty("ta")&&chatResponse.hasOwnProperty("cc")
      &&chatResponse.hasOwnProperty("lr")&&chatResponse.hasOwnProperty("gra")&&chatResponse.hasOwnProperty("task")){
        document.getElementById('await-container').classList.add('hidden');
        this.revised = chatResponse.revised;
        this.advice = chatResponse.advice;
        this.overall = chatResponse.overall;
        this.ta = chatResponse.ta;
        this.cc = chatResponse.cc;
        this.lr = chatResponse.lr;
        this.gra= chatResponse.gra;
        this.task = chatResponse.task;
        
      }else{
        this.throwError("property insufficient","发生错误，这不是你的问题。请稍后重试。")
      }
    }
  },
}
</script>

<style scoped>
.hidden{
  display: none !important;
}
.await-container {
  position:fixed;
  z-index: 69;
  float: left;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.await-await {
  gap: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 107px 93px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(253, 253, 253, 1);
}
.await-content {
  gap: 57px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.await-banner {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.await-logo {
  width: 86px;
  height: 86px;
}
.await-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.await-text2 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.result-result {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 0 93px;
  padding-left: 60px;
  padding-right: 60px;
  flex-direction: column;
  padding-bottom: 0 93px;
}
@media screen and (max-width: 991px) {
  .result-result {
    padding-left: 7px;
    padding-right: 7px;
  }
  
}
.result-content {
  gap: 14px;
  display: flex;
  padding: 50px 0 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.result-banner {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.result-logo {
  width: 86px;
  height: 86px;
}
.result-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-frame22 {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.result-score {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.result-frame {
  display: flex;
  align-self: stretch;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05999999865889549) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
}
.result-scoretotal {
  gap: 11px;
  display: flex;
  padding: 17px 42px 25px 47px;
  overflow: hidden;
  flex-grow: 0.5;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px;
  border-radius: 12px 0 0 12px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(24, 175, 0, 1);
}
.result-text02 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text04 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 36px;
  font-style: Black;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 900;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-container1 {
  flex-grow: 0.5;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.result-scoreachievement {
  gap: 11px;
  display: flex;
  padding: 17px 42px 25px 47px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px 3px 3px 0;
  flex-direction: column;
  justify-content: center;
}
.result-text06 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text08 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 36px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-scorecohesion {
  gap: 11px;
  display: flex;
  padding: 17px 42px 25px 47px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px 3px 3px 0;
  flex-direction: column;
  justify-content: center;
}
.result-text10 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text12 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 36px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-scorelexical {
  gap: 11px;
  display: flex;
  padding: 17px 42px 25px 47px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px 3px 3px 0;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .result-scorelexical {
    border-width: 3px;
    border-radius: 12px;
  }
  .result-scorecohesion  {
    border-width: 3px;
    border-radius: 12px;
  }.result-scoreachievement {
    border-width: 3px;
    border-radius: 12px !important;
  }
  .result-scoregrammar {
    border-width: 3px !important;
    border-radius: 12px !important;
  }
  
}
.result-text14 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text16 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 36px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-scoregrammar {
  gap: 11px;
  display: flex;
  padding: 17px 42px 25px 47px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px 3px 3px 0;
  border-radius: 0 12px 12px 0;
  flex-direction: column;
  justify-content: center;
}
.result-text18 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Medium;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text20 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 36px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text22 {
  color: rgba(153, 153, 153, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-advice {
  gap: 14px;
  display: flex;
  padding: 23px 35px;
  align-self: stretch;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05999999865889549) ;
  align-items: flex-start;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.result-text24 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text26 {
  color: rgba(79, 79, 79, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: medium;
  font-size: medium;
  font-stretch: normal;
  text-decoration: none;
}
.result-reference {
  gap: 14px;
  display: flex;
  padding: 23px 35px;
  align-self: stretch;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05999999865889549) ;
  align-items: flex-start;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.result-original {
  gap: 14px;
  display: flex;
  padding: 23px 35px;
  align-self: stretch;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.05999999865889549) ;
  align-items: flex-start;
  border-color: rgba(240, 241, 242, 1);
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.result-text28 {
  width: 100%;
  justify-content:space-between;
  display: flex;
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.result-text30 {
  color: rgba(79, 79, 79, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  line-height: 20px;
  font-size: medium;
  font-stretch: normal;
  text-decoration: none;
  
}
.result-bottombar {
  padding: 10px 0px;
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: right;
}
@media(max-width: 991px) {

  .result-container1 {
  width: 100%;

}
  .result-scoreachievement{
    border-width: 3px;
    border-radius: 12px 0px 0px 12px;
  }
.result-scoretotal{
  border-radius: 12px;

}
  .result-frame {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .result-scoretotal {
    flex-grow: 2;
    flex-wrap: wrap-reverse;
  }
}
</style>
