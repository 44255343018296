<template>
  <div class="homepage-container">

    <div class="homepage-homepage">
      <div class="homepage-content">
        <div class="homepage-banner">
          <img
            alt="logo2179"
            src="/external/logo2179-jgw-200h.png"
            class="homepage-logo"
          />
          <span class="homepage-text"><span>雅思写作帮手</span></span>
          <span class="homepage-text02">
            <span class="homepage-text03">利用AI技术，一键获取作文预估分数、修改意见和高分范文。</span>
          </span>
        </div>
        <div class="homepage-frame15 hidden">
          <button class="button-stylized-minimized" disabled> 批改你的作文</button>
          <button class="button-stylized-minimized"> 通过题目获取范文</button>

        </div>
        <div class="homepage-frame16">
          <span class="homepage-text13"><span>作文回答</span></span>
          <div class="homepage-textarea1">
            <textarea class="homepage-textarea" v-model="textareaData" placeholder="在这里粘贴你的作文答案，无需区分Task1或Task2">
        </textarea>
        <span style="color:#e52a33;" id="error-message" class="hidden"><span>{{ this.errorMessage }}</span></span>
          </div>
        </div>
 
        <div class="homepage-frame4">
          <button type="button" @click="StartTrial" class="button button-stylized">手头没有作文，先试一篇</button>

          <button type="button" @click="StartAnalyse" class="button button-stylized">批改</button>
        </div>
        <div class="homepage-frame24">
          <div class="homepage-frame6">
            <div class="homepage-frame23">
              <img
                src="/external/good.png"
                alt="Vector5977"
                class="homepage-vector"
              />
              <span class="homepage-text16"><span>我能做到...</span></span>
            </div>
            <span class="homepage-text18">
              <span>
                <span>给你的文章初步估分；</span>
                <br />
                <span>丰富你的词汇量和短句储备；</span>
                <br />
                <span>改写你的文章，使之更加丰满；</span>
                <br />
                <span>为你提供简单的意见和建议。</span>
              </span>
            </span>
          </div>
          <div class="homepage-frame8">
            <div class="homepage-frame231">
              <img
                src="/external/error.png"
                alt="Vector5984"
                class="homepage-vector1"
              />
              <span class="homepage-text27"><span>我做不到...</span></span>
            </div>
            <span class="homepage-text29">
              <span>
                <span>替代你的老师，替代一个真人；</span>
                <br />
                <span>分析你的文章是否符题达意；</span>
                <br />
                <span>判断你的文章是否包含了题目中的所有信息；</span>
                <br />
                <span>将你文章中缺失的信息为你补全。</span>
              </span>
            </span>
          </div>
        </div>
        <!-- <div class="homepage-frame4">
          <button type="button" @click="StartAnalyse" class="button button-stylized">debugMode</button>
        </div> -->
        <!-- 雅思写作帮手已经免费提供了{{estimatedCost}}美元的算力。 -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Homepage',
  metaInfo: {
    title: '雅思写作帮手',
  },
  data(){
    return{
      textareaData:'',
      errorMessage:'',
      estimatedCost:0
    }
  },
  mounted() {
    //a function that calculate estimated cost based on time
    var timeOfPublish = new Date('2024-01-19');
    var timeNow = Date.now()
    console.log(timeOfPublish);
    //calculate seconds
    var seconds = (timeNow -timeOfPublish) / 1000;
    var cost = 0.0001 * seconds;
    cost = cost.toFixed(2);
    this.estimatedCost = cost;
  },
  methods: {
    StartTrial(){
      this.$router.replace({ path: '/result', query: { isTrial : 1,data: "Today, many ads are all about showcasing new and cool stuff. This essay will look into why this happens and why it's generally a good thing. One reason is that people just love new things. Studies on how people think when they shop show that we are naturally attracted to things that are different and fresh. For example, when phone companies talk about the cool features of their latest models like super cameras or folding screens, they know we're likely to be interested because we're curious creatures. Another reason for promoting products as innovative is that it makes us think they're more valuable. If something is labeled as cutting-edge, it gives the impression that it's better than other similar things. This can be a big deal for people who want the best experience and feel like they're getting their money's worth. They might choose a pricier product just because it's got the latest and greatest features. Even though there are some downsides to this, like encouraging a constant need for new stuff and making things obsolete quicker, the overall effect of this kind of advertising is mostly good. The focus on new stuff pushes companies to come up with cool and useful things, making life better for many people. It also pushes businesses to invest in new ideas, helping the economy grow and creating more jobs. So, even though there are some not-so-great things about it, the emphasis on new and cool things in advertising is mostly a good thing." } });
    },
    ShowErrorMessage(string){
      document.getElementById('error-message').classList.remove('hidden');
      this.errorMessage = string;
    },
    StartAnalyse(){
      //if textareaData is less than 200 words, alert
      if(this.textareaData.split(' ').length < 110){
        this.ShowErrorMessage('你至少需要有110字以上才可批改。官方评分要求：Task1需150字以上，Task2需250字以上。')
        return;
      }
      this.$router.push({ path: '/result', query: { data: this.textareaData } });
    }
  }
}
</script>

<style scoped>
.hidden{
  display: none !important;
}
.homepage-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.homepage-homepage {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 107px 93px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(253, 253, 253, 1);
}
.homepage-content {
  gap: 57px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.homepage-banner {
  gap: 20px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.homepage-logo {
  width: 86px;
  height: 86px;
}
.homepage-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text02 {
  color: rgba(108, 114, 128, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text03 {
  font-weight: 400;
}
.homepage-frame15 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.homepage-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-textarea {
  gap: 10px;
  height: 135px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: #595959;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: #ededed;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  font-style: normal;
    font-family: Noto Sans SC;
    padding-top: 10px;
    padding-left: 10px;
}
.homepage-text11 {
  top: 8.117088317871094px;
  left: 19px;
  color: rgba(153, 153, 153, 1);
  width: auto;
  height: auto;
  z-index: 1;
  position: absolute;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-frame16 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.homepage-text13 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-textarea1 {
  gap: 10px;
  height: 135px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: #595959;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: #ededed;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.homepage-text15 {
  top: 8.117088317871094px;
  left: 19px;
  color: rgb(153, 153, 153);
  width: auto;
  height: auto;
  z-index: 1;
  position: absolute;
  font-size: 20px;
  font-style: Regular;
  text-align: left;
  font-family: "Noto Sans SC";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-frame4 {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-end;
  justify-content: center;
}
@media(max-width: 991px) {
  .homepage-frame4 {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media(max-width: 767px) {
  .homepage-homepage {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media(max-width: 479px) {
  .homepage-homepage {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.homepage-frame24 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 8px;
  border-color: #d6d6d6;
  border-width: 1px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.homepage-frame6 {
  gap: 11px;
  display: flex;
  padding: 20px 34px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(10, 72, 0, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(226, 235, 225, 1);
}
.homepage-frame23 {
  gap: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-vector {
  width: 20px;
  height: 20px;
}
.homepage-text16 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-frame8 {
  gap: 11px;
  display: flex;
  padding: 20px 34px;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(126, 8, 20, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  flex-direction: column;
  background-color: rgba(235, 227, 225, 1);
}
.homepage-frame231 {
  gap: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-vector1 {
  width: 20px;
  height: 20px;
}
.homepage-text27 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 20px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.homepage-text29 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .homepage-frame4 {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media(max-width: 767px) {
  .homepage-homepage {
    padding-left: 40px;
    padding-right: 40px;
  }
  .homepage-frame24 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .homepage-homepage {
    padding-left: 20px;
    padding-right: 20px;
  }
}


</style>
