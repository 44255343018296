<template>

</template>

<script>
export default {
  name: 'Await',
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>

</style>
